import * as Sentry from '@sentry/react';
import axios from 'axios';

export type SentryHttpErrorcodeFilter = { [code: number]: 'eq' | 'ne' | 'lt' | 'gt' | 'lte' | 'gte' };

function isFilteredSentryEvent(
  errorCodeFilter: SentryHttpErrorcodeFilter,
  hint: Pick<Sentry.EventHint, 'originalException'>,
): boolean {
  if (!axios.isAxiosError(hint.originalException) || hint.originalException.response?.status === undefined) {
    return false;
  }

  const errorStatusCode: number = hint.originalException.response.status;
  return Object.entries(errorCodeFilter).some(([code, operator]) => {
    const codeNumber = +code;

    switch (operator) {
      case 'eq':
        return errorStatusCode === codeNumber;
      case 'ne':
        return errorStatusCode !== codeNumber;
      case 'lt':
        return errorStatusCode < codeNumber;
      case 'gt':
        return errorStatusCode > codeNumber;
      case 'lte':
        return errorStatusCode <= codeNumber;
      case 'gte':
        return errorStatusCode >= codeNumber;
      default:
        return false;
    }
  });
}

function makeSentryHttpErrorFilterFunction(
  errorCodeFilter: SentryHttpErrorcodeFilter,
): Required<Sentry.BrowserOptions>['beforeSend'] {
  return (event: Sentry.Event, hint: Sentry.EventHint) => (isFilteredSentryEvent(errorCodeFilter, hint) ? null : event);
}

export default makeSentryHttpErrorFilterFunction;
