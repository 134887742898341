/**
 * Finds the local IP address of the browser using WebRTC.
 * Usually this is blocked by the browser and you only get mDNS addresses.
 * For Chrome, to get the real user ip set #enable-webrtc-hide-local-ips-with-mdns to disabled in chrome://flags
 */
const findLocalIps = (): Promise<string[]> =>
  new Promise((resolve, reject) => {
    if (typeof window.RTCPeerConnection == 'undefined') return reject('WebRTC not supported by browser');

    const pc = new RTCPeerConnection();
    const ips: string[] = [];

    pc.createDataChannel('');
    pc.createOffer()
      .then(offer => pc.setLocalDescription(offer))
      .catch(err => reject(err));
    pc.onicecandidate = event => {
      if (!event || !event.candidate) {
        // All ICE candidates have been sent.
        if (ips.length === 0) return reject('WebRTC disabled or restricted by browser');

        return resolve(ips);
      }

      const parts = event.candidate.candidate.split(' ');
      const ip = parts[4];
      ips.push(ip);

      return Promise.resolve(ips);
    };
  });

export const localIp = {
  findLocalIps,
};
