import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Buffer } from 'buffer';
import * as serviceWorker from './app/serviceWorker';
import { localIp } from './components/common-components/util/localIp';

import './app/index.css';
import makeSentryHttpErrorFilterFunction from './components/basic-components/utils/sentryFilter';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//making buffer available globally in browser environment
window.Buffer = Buffer;

async function loadConfigAndBootstrap() {
  const response = await fetch('/config.json');
  if (!response.ok) {
    throw new Error('Could not load config.json');
  }
  const config = await response.json();
  window.ENV = config;

  if (window.ENV.SENTRY_ENABLED === 'true') {
    const tracesRate = window.ENV.SENTRY_TRACES_RATE || '0.0';
    const releaseTag = `${window.ENV.DEPLOY_BRANCH}-${window.ENV.STAGE}-${window.ENV.BUILD_VERSION}`;
    Sentry.init({
      dsn: window.ENV.SENTRY_DSN,
      integrations: [
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/plugin/
        Sentry.browserTracingIntegration(),
        captureConsoleIntegration({
          // array of methods that should be captured
          // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
          levels: ['warn', 'error'],
        }),
      ],
      transport: Sentry.makeBrowserOfflineTransport(options => Sentry.makeFetchTransport(options)),
      release: releaseTag,
      environment: window.ENV.STAGE,
      // filter out 500 errors or higher
      beforeSend: makeSentryHttpErrorFilterFunction({ 500: 'gte' }),
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: +`${tracesRate}`,
      tracePropagationTargets: [/^\//],
    });
    Sentry.setTag('deploy_branch', window.ENV.DEPLOY_BRANCH);
    Sentry.setTag('build_version', window.ENV.BUILD_VERSION);
    try {
      const ips = await localIp.findLocalIps();
      if (ips) {
        for (let i = 0; i < ips.length; i++) {
          Sentry.setTag(`local_ip-${i}`, ips[i]);
        }
      }
    } catch (error: unknown) {
      // ignore
    }
  }

  const { bootstrap } = await import('./app/bootstrap');
  bootstrap();
}

void loadConfigAndBootstrap();
